import * as React from 'react';
import * as Styles from '../styles/pages/_entry.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import Accent from '/static/accent.svg';
import classNames from 'classnames';
import {Link, useIntl} from "gatsby-plugin-react-intl"

const EntryPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  return (
    <Layout location={location}>
      <Seo title={t('entry.seo.title')} description={t('entry.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.firstLIMITS'),
          },
        ]}
        currentPage={t('menu.joinLIMITS')}
      />
      <BackGround>
        <section className={classNames(Styles.entry, CommonStyles.wrapper)}>
          <div className={Styles.entry__wrap}>
            <div className={CommonStyles.container}>
              <h1>{t('menu.joinLIMITS')}</h1>
              <div className={Styles.entry__contents}>
                <h3>
                  <img src={Accent} className="accent" alt="accent" />
                  {t('entry.join.title')}
                </h3>
                <div className={Styles.entry__flow}>
                  <StaticImage src="../images/entry.png" alt={t('entry.join.title')} className="pc" />
                  <StaticImage src="../images/entry_sp.png" alt={t('entry.join.title')} className="sp" />
                </div>
                <div className={Styles.entry__text}>
                  <p>
                    {t('entry.join.text1')}
                    <br />
                    {t('entry.join.text2')}
                  </p>
                  <p>
                    {t('entry.join.text3')}
                    <br />
                    {t('entry.join.text4')}
                  </p>
                </div>
              </div>
              <div className={Styles.entry__contents}>
                <h3>
                  <img src={Accent} className="accent" alt="accent" />
                  {t('entry.entryEvent.title')}
                </h3>
                <div className={Styles.entry__text}>
                  <p>
                    {t('entry.entryEvent.text1')}
                    <br />
                    {t('entry.entryEvent.text2')}
                    <br />
                    {t('entry.entryEvent.text3')}
                    <br />
                    {t('entry.entryEvent.text4')}
                  </p>
                  <p>
                    {t('entry.entryEvent.text5')}
                    <br />
                    {t('entry.entryEvent.text6')}
                    <br />
                    {t('entry.entryEvent.text7')}
                  </p>
                </div>
              </div>
              <div className={Styles.entry__link}>
                <p>
                  <Link to={`/faq/`}>{t('entry.faq.title')}</Link>
                </p>
                <p>
                  <Link to={`/contact/`}>
                    {t('entry.faq.text1')}
                    <br className={Styles.entry__sp} />
                    {t('entry.faq.text2')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
}

export default EntryPage;
